<template>
	<div class="originPie_box">
		<div :id="pieId"></div>
	</div>
</template>

<script>
	export default {
		props: {
			pieId: {
				// 元素id
				type: String,
				default: ""
			},
			pieData:{
				type:Array,
				default: function() {
					return []
				}
			}
		},
		data() {
			return {
				height: "337px",
				taskAll:0
			};
		},
		// created() {
		// 	setTimeout(() => {
		// 		this.drawPie();
		// 	});
		// },
		watch:{
			pieData:{
				handler(val){
					if(val){
						console.log(val)
						var strArr = [];
						for (let i in val) {
							strArr.push(val[i]['value'])
						};
						this.taskAll = eval(strArr.join('+'))
						this.drawPie()
					}
				},
				deep:true
			},
		},
		methods: {
			drawPie() {
				let _this = this;
				let pieChart = this.$Echarts.init(document.getElementById(this.pieId));
				let option = {
					tooltip: {
						trigger: "item",
						formatter: function(params) {
							let tipFormat = `<p ><span style="width: 174px;display: flex;align-items: center;justify-content: space-between;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;line-height: 20px;color: #333333;margin: 7px 0;">
				<span>任务数</span>
				<span>${params.data.value}</span>
			</span><span style="width: 174px;display: flex;align-items: center;justify-content: space-between;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;line-height: 20px;color: #333333;margin: 7px 0;">
				<span>占比</span>
				<span>${params.percent}%</span></p>`;
							return tipFormat;
						}
					},
					graphic: [
						{ //图形中间文字
							type: "text",
							left: "center",
							top: "43%",
							style: {
								text: this.taskAll,
								textAlign: "center",
								fill: "#333333",
								fontSize: 30
							}
						},
						{ //图形中间文字
							type: "text",
							left: "center",
							top: "55%",
							style: {
								text: "任务总数",
								textAlign: "center",
								fill: "#999999",
								fontSize: 12
							}
						},
					],
					color: ["#5BA897", "#B6B084"],
					legend: {
						top: "3%",
						right: "24px",
						icon: "circle",
						itemWidth: 10, // 设置宽度
						itemHeight: 10, // 设置高度
						show: true
					},
					series: [{
						name: "",
						type: "pie",
						radius: ["40%", "65%"],
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: "center"
						},
						emphasis: {
							label: {
								show: false,
								fontSize: "40",
								fontWeight: "bold"
							}
						},
						labelLine: {
							show: false
						},
						data:this.pieData,
					}],

				};
				pieChart.setOption(option);
				window.addEventListener("resize", () => {
					pieChart.resize();
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	p {
		padding: 0;
		margin: 0;
	}

	.originPie_box {
		background-color: rgba(0, 0, 0, 0);
	}

	#originPie {
		width: 100%;
		height: 337px;
		cursor: pointer
	}
</style>
