<template>
	<div class="taskStatistics">
		<div class="taskStatistics_back">
			<div @click="backTo" class="taskStatistics_back_btn">
				<img src="@/assets/images/dashboard/back.png">
				<span>返回</span>
			</div>
		</div>
		<div class="taskStatistics_content">
			<div class="taskStatistics_content_title">
				<img src="@/assets/images/dashboard/total.png" class="">
				<span>任务完成情况统计</span>
			</div>
			<div class="taskStatistics_content_box">
				<div class="taskStatistics_content_inner">
					<div class="taskStatistics_content_head">
						<div class="content_head_left">
							<span
								class="content_head_circle"></span>今日已完成任务（<span>{{taskData.todayYes?taskData.todayYes.length:0}}</span>）
						</div>
					</div>
					<div class="taskStatistics_content_list">
						<div v-for="(item,index) in taskData.todayYes" :key="index">
							<div class="taskStatistics_content_item task_green" v-if="item.source==1">
								{{item.title}}
							</div>
							<div class="taskStatistics_content_item task_yellow" v-if="item.source==2">
								{{item.title}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="taskStatistics_content_box">
				<div class="taskStatistics_content_inner">
					<div class="taskStatistics_content_head">
						<div class="content_head_left">
							<span class="content_head_circle"></span>本周已完成任务（<span
								v-if="taskData.weekYes">{{taskData.weekYes.length}}</span><span v-else>0</span>）
						</div>
					</div>
					<div v-if="taskData.weekYes&&taskData.weekYes.length > 0" class="taskStatistics_content_list">
						<div v-for="(item,index) in taskData.weekYes" :key="index">
							<div class="taskStatistics_content_item task_green" v-if="item.source==1">
								{{item.title}}
							</div>
							<div class="taskStatistics_content_item task_yellow" v-if="item.source==2">
								{{item.title}}
							</div>
						</div>
					</div>
				</div>
				<div class="taskStatistics_content_chart">
					<div class="taskStatistics_content_head">
						<div class="content_head_left">
							<span class="content_head_circle"></span>来源占比圆饼图
						</div>
					</div>
					<div class="content_chart_box">
						<originPie :pieData="pieData" pieId="originPie" />
					</div>
				</div>
			</div>
			<div class="taskStatistics_content_box">
				<div class="taskStatistics_content_inner">
					<div class="taskStatistics_content_head">
						<div class="content_head_left">
							<span class="content_head_circle"></span>其他时间已完成任务
						</div>
					</div>
					<div class="taskStatistics_content_search">
						<el-form class="flex_item" ref="searchForm" :model="searchForm" label-width="70px" size="mini">
							<el-form-item class="flex_item" label="日期选择" prop="otherTime">
								<el-date-picker class="taskStatistics_content_ipt" v-model="otherTime" type="daterange"
									range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
									@change="pickerChange">
								</el-date-picker>
							</el-form-item>
							<el-form-item class="flex_item" label="关键词搜索" prop="keyword">
								<input class="taskStatistics_content_ipt" v-model="searchForm.keyword"
									placeholder="请输入关键词"></input>
							</el-form-item>
						</el-form>
						<div class="taskStatistics_content_btn">
							<span class="taskStatistics_content_confirm" @click="submit('query')">查询</span>
							<span class="taskStatistics_content_cancel" @click="submit('reset')">重置</span>
						</div>
					</div>
					<div class="taskStatistics_content_list">
						<div v-for="(item,index) in otherTask" :key="index">
							<div class="taskStatistics_content_item task_green" v-if="item.source==1">
								{{item.title}}
							</div>
							<div class="taskStatistics_content_item task_yellow" v-if="item.source==2">
								{{item.title}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import originPie from "./components/originPie.vue"
	import commonFn from "@/utils/common.js";
	import {
		statisticalTask,
		otherTimeTask
	} from "@/api/dashboards"
	export default {
		components: {
			originPie
		},
		data() {
			return {
				searchForm: {
					backlogDateStart: "",
					backlogDateEnd: "",
					keyword: ""
				},
				taskData: {},
				otherTime: [],
				otherTask: [],
				pieData: [],
			}
		},
		created() {
			this.getStatisticalTask()
			this.otherTimeTaskData()
		},
		methods: {
			backTo() {
				this.$router.push({
					path: "/dashboard",
				});
			},
			//获取任务统计数据
			getStatisticalTask() {
				statisticalTask().then(res => {
					this.pieData = []
					if (res.code == 0) {
						this.taskData = res.data
						let arr = [{
							type: "1",
							name: "自行添加",
							value: "0",
						}, {
							type: "2",
							name: "派送任务",
							value: "0",
						}]
						if (res.data.sourceRatio) {
							let objSource = res.data.sourceRatio
							arr.forEach(item => {
								item.value = objSource[item.type] ? Number(objSource[item.type]) : 0
							})
							this.pieData = arr
						} else {
							this.pieData = arr
						}
					}
				})
			},
			//其他时间选择
			pickerChange(value) {
				console.log(value, '其他日期')
				this.searchForm.backlogDateStart = commonFn.timeFormat(value[0], "yyyy-MM-dd")
				this.searchForm.backlogDateEnd = commonFn.timeFormat(value[1], "yyyy-MM-dd")
			},
			//其他时间已完成任务
			otherTimeTaskData() {
				otherTimeTask(this.searchForm).then(res => {
					if (res.code == 0) {
						this.otherTask = res.data
					}
				})
			},
			//查询重置
			submit(type) {
				switch (type) {
					case "query":
						this.otherTimeTaskData()
						break;
					case "reset":
						this.searchForm = {}
						this.otherTime = []
						this.otherTimeTaskData()
						break;
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../styles/index.scss";

	/deep/.el-form-item {
		margin: 0;
		display: flex;
	}

	/deep/.el-form-item__label {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #333333;
		margin-right: 10px;
		padding: 0;
	}

	/deep/.el-form-item__content {
		flex: 1;
		line-height: 20px;
		margin: 0 !important;
	}

	input::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}


	.task_green {
		background: #EEF6F4;
		border-left: 1px solid $theme_color;
		color: $theme_color;
	}

	.task_yellow {
		background: #F7F5F1;
		border-left: 1px solid #B39A77;
		color: #B39A77;
	}

	.taskStatistics {
		display: flex;
		flex-direction: column;
		background: #FFFFFF;
		border-radius: 10px 10px 10px 0px;
		margin-top: 20px;

		.taskStatistics_back {
			padding-top: 10px;

			.taskStatistics_back_btn {
				width: 129px;
				line-height: 42px;
				background: #FFFFFF;
				box-shadow: 0px 4px 6px rgba(51, 51, 51, 0.16);
				border-radius: 0px 20px 20px 0px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				img {
					width: 21px;
					height: 16px;
					margin-right: 8px;
				}

				span {
					font-size: 18px;
					font-family: Source Han Sans CN;
					font-weight: 500;
					color: #333333;
				}
			}
		}

		.taskStatistics_content {
			display: flex;
			flex-direction: column;
			padding: 0 40px;

			.taskStatistics_content_title {
				display: flex;
				align-items: center;
				margin: 19px 0 17px 0;

				img {
					width: 16px;
					height: 16px;
					margin-right: 10px;
				}

				sspan {
					font-size: 16px;
					font-family: Source Han Sans CN;
					font-weight: 500;
					line-height: 24px;
					color: #333333;
				}
			}

			.taskStatistics_content_box {
				display: flex;
				margin-bottom: 20px;

				.taskStatistics_content_inner {
					flex: 1;
					background: #FFFFFF;
					box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
					border-radius: 4px;
					display: flex;
					flex-direction: column;

					.taskStatistics_content_search {
						display: flex;
						align-items: center;
						margin: 20px 39px 0px 39px;

						.taskStatistics_content_ipt {
							width: 320px;
							line-height: 34px;
							outline: none;
							border: 1px solid #EEEEEE;
							border-radius: 4px;
							padding: 0 10px;
							box-sizing: border-box;
							margin-right: 40px;
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #000000;
						}

						.taskStatistics_content_btn {
							display: flex;
							align-items: center;

							.taskStatistics_content_confirm {
								width: 92px;
								line-height: 34px;
								background: $theme_color;
								border-radius: 4px;
								text-align: center;
								font-size: 14px;
								font-family: Source Han Sans CN;
								font-weight: bold;
								color: #FFFFFF;
								cursor: pointer;
								margin-right: 16px;
							}

							.taskStatistics_content_cancel {
								width: 92px;
								line-height: 34px;
								border: 1px solid $theme_color;
								border-radius: 4px;
								text-align: center;
								font-size: 14px;
								font-family: Source Han Sans CN;
								font-weight: bold;
								color: $theme_color;
								cursor: pointer;
							}
						}
					}

					.taskStatistics_content_list {
						flex: 1;
						padding: 20px 24px 0 24px;
						display: flex;
						// align-items: center;
						flex-wrap: wrap;

						.taskStatistics_content_item {
							width: 190px;
							height: 72px;
							margin-bottom: 20px;
							padding: 6px 11px;
							box-sizing: border-box;
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							margin-right: 34px;
							line-height: 21px;
							word-break: break-all;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 3;
							overflow: hidden;
						}
					}
				}

				.taskStatistics_content_head {
					display: flex;
					justify-content: space-between;
					font-size: 16px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					line-height: 24px;
					color: #333333;
					padding: 7px 24px 9px 24px;
					border-bottom: 1px solid #EEEEEE;

					.content_head_left {
						display: flex;
						align-items: center;

						.content_head_circle {
							width: 7px;
							height: 7px;
							background: #000000;
							border-radius: 50%;
							margin-right: 8px;
							display: inline-block;
						}
					}

					.content_head_right {
						display: flex;
						align-items: center;
					}
				}

				.taskStatistics_content_chart {
					width: 666px;
					height: 337px;
					background: #FFFFFF;
					box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
					border-radius: 4px;
					margin-left: 20px;
					position: relative;

					.content_chart_box {
						width: 666px;
						height: 337px;
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}
		}
	}
</style>
